.main-deposit .max {
  display: grid;
  grid-template-columns: 30px calc(100% - 30px - 4px);
  column-gap: 40px;
  max-width: 780px;
  margin: 0 auto;
}

/deep/ .el-steps {
  .el-step:nth-child(1) {
    flex-basis: 230px !important;
  }

  .el-step:nth-child(2) {
    flex-basis: calc(100% - 280px) !important;
  }
}

@media (max-width: 767px) {
  .main-deposit {
    .max {
      column-gap: 10px;
    }
  }

  /deep/ .el-steps {
    .el-step:nth-child(1) {
      flex-basis: 480px !important;
    }

    .el-step:nth-child(2) {
      flex-basis: calc(100% - 480px) !important;
    }
  }
}
