.cc-box {
  border-radius: 8px;
  background-color: $white;
  padding: 20px 100px;

  .main {
    border-radius: 8px;
    background-color: $white;
    padding: 40px 120px;
    max-width: 1200px;

    > div {
      max-width: 780px;
      margin: 0 auto;
    }

    p {
      font-size: 15px;
      color: $blue;
    }

    .credit-success {
      text-align: center;

      p {
        font-size: 15px;
        color: rgb(98, 109, 113);
        line-height: 1.667;
        margin-top: 40px;
        margin-bottom: 100px;
      }

      .creditOrDebitUpload {
        display: grid;
        grid-template-columns: 45% 50%;
        column-gap: 5%;
      }

      .btn-red {
        margin-top: 30px;
        width: 100%;
        max-width: 339px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .cc-box {
    padding: 20px 60px;

    .main {
      padding: 40px;
    }
  }
}

@media (max-width: 768px) {
  .cc-box {
    padding: 20px;

    .main {
      padding: 20px;
    }
  }
}

@media (max-width: 767px) {
  .cc-box {
    padding: 0;

    .main {
      background-color: $white;

      .credit-success {
        p {
          margin-top: 20px;
          margin-bottom: 20px;
        }
        .creditOrDebitUpload {
          grid-template-columns: repeat(auto-fill, 100%);
        }
      }
    }

    /deep/ .success {
      background-color: $white;
    }
  }
}
